import React, { useEffect } from 'react';
import bannerWallpaper from './../assets/photowallpaper.png';

function HomeNoAuth() {

  return (
    <div className="inn-container-card">
      <div className="login-section-promoter">
         <div className="a1Size"> Welcome to PHOTOCUTT</div>
         <div className="a2Size">To enjoy the platform please register it is free.</div>
         <div className="pd10 text-center">
          <img src={bannerWallpaper} className="img-responsive img-rp" />
         </div>
      </div> 
    </div>
  )
}

export default HomeNoAuth
/*
This wigest is to randomly fetch one visible album
Display the picture on a horizonal scroller that doesn't have link throughs
*/