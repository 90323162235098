import React from 'react'
import { Link } from 'react-router-dom'
import './topNav.css';

function FooterHolder() {
  const d = new Date();

  return (
    <div className="footwork">
      <ul className="copy-list">
        <li><Link to="/">HOME</Link></li>
        <li><Link to="/about">ABOUT</Link></li>
        <li><Link to="/terms-of-use">TERMS OF USE</Link></li>
        <li><Link to="/privacy">PRIVACY</Link></li>
      </ul>

      <div className="copy-text">
       <span> &copy;</span>
       <span> {d.getFullYear()} </span>
      <Link to="https://www.tsdevcut.co.za/" target="_blank">TSDEVCUT</Link>
      </div>
    </div>
  )
}

export default FooterHolder