import React, { useRef, useState } from 'react'
import TopNavigation from '../Components/TopNavigation'
import axios from "axios";
import { toast } from 'react-toastify';
import * as CONSTANTS from "./../CONSTANTS";
import imageAlbum from "./../assets/albumcreate.png"
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import FooterHolder from '../Components/FooterHolder';

function AlbumCreate() {
    const [processing, setProcessing]                             = useState(false);
    const {user}                                                  = useSelector((state) => state.auth);

    const [albumURL, setAlbumURL]                                 = useState("");

    const titleRef                                           = useRef();
    const biorRef                                            = useRef();
    const visibilityRef                                      = useRef();

    async function HandleCreatingAlbum(e) {
        e.preventDefault();  
        setProcessing(true);   

        try{

            const objectAlbum = {
                "title" : titleRef.current.value,
                "description" : biorRef.current.value,
                "visible" : visibilityRef.current.value,
                "userid" : user._id
            }

            const res = await axios.post(CONSTANTS.API_URL +"albums/",objectAlbum, {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });
        
            if(res.status == 201){
                toast.success("Your album has been created.");
                titleRef.current.value    = "";
                biorRef.current.value     = "";
                setAlbumURL(res.data._id);

            }else {
                toast.error("Album was not successful, please try again later.");
            }
            setProcessing(false); 
        }catch(err){
            setProcessing(false);  
            console.log(err);
            toast.error("Something went wrong, please try again later.");
        }
    }
  return (
    <div className="stream-container">
      <div className="upper-top">
          <TopNavigation />
      </div>
      <div className="content-display">
          <div className="initial-title">
            Create Album
          </div>
          <div className="row">
          <div className="col-md-6">
          <div className="form-item form-pharm">
                <form onSubmit={HandleCreatingAlbum}>
                <div className="form-group">
                    <div className="lbldesc">Album Name*:</div>
                    <input type="text" className="form-control wide100" ref={titleRef} required/>
                </div>
                <div className="form-group">
                    <div className="lbldesc">Album Bio:</div>
                    <textarea className="form-control wide100" ref={biorRef} ></textarea>
                </div>
                
                <div className="form-group">
                    <div className="lbldesc">Status*:</div>
                    <select className="form-control wide100" ref={visibilityRef} >
                        <option value={true}>Public (visible to any user)</option>
                        <option value={false}>Private (Private to you)</option>
                    </select>
                </div> 
                
                    <div className="form-group">
                        <button type="submit" className="btn btn-photocut" disabled={processing}>Create</button>                             
                    </div>
                </form>
            </div>
                {
                    processing && (
                        <div className="img-source">
                        <img src={CONSTANTS.BLUE_PROCESSING} className="proc" />
                        </div>
                    )
                }

                {
                    albumURL && (<Link to={"/single-album/" + albumURL}>View Album</Link>)
                }
          </div>
          <div className="col-md-6">
                <div className="base-art">
                    <img src={imageAlbum} className="on-display img-responsive" alt="Album Work" />
                </div>
          </div>
          </div>
      </div>
      <div className="below-bottom">
          <FooterHolder />
        </div>
    </div>
  )
}

export default AlbumCreate