import React, { useEffect, useState } from 'react'
import * as CONSTANTS from "./../CONSTANTS";
import axios from "axios";
import { FaUser, FaInstagram, FaFacebook, FaTwitter, FaHeart, FaRegHeart, FaArrowsAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function HomeShortList({user}) {
    const [randomAlbums, setRandomAlbums]                   = useState([]);

    useEffect(() => {
        collectRandomAlbums();
    },[]);

    const collectRandomAlbums = async () => {
        try{

                
           const resRandom = await axios.get(CONSTANTS.API_URL +"albums/random/albums/limit", {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });
            //console.log(resRandom);
            setRandomAlbums(resRandom.data);
        }catch(err){
            console.log(err);
        }
    }

    const likeCurrentAlbum = async (album) => {
      try{

        const content = {
            "albumid": album._id,
            "userid": user._id
        }

        const resAlbumLikes = await axios.put(CONSTANTS.API_URL +"albums/likes/add", content,{
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });
       
     
        if(resAlbumLikes.status == 200){
            updateLikes(resAlbumLikes.data);
        }

      }catch(err){
        console.log(err);
      }
    }

    const unLikeCurrentAlbum = async (album) => {
        try{

  
          const content = {
              "albumid": album._id,
              "userid": user._id
          }
  
          const resAlbumLikes = await axios.put(CONSTANTS.API_URL +"albums/likes/remove", content,{
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });
   
          if(resAlbumLikes.status == 200){
              updateLikes(resAlbumLikes.data);
          }
          
  
        }catch(err){
          console.log(err);
        }
      }

    const updateLikes = (newUpdated) => {
        setRandomAlbums(prevAlbums => 
          prevAlbums.map(album => 
            album._id === newUpdated._id ? { ...album, likes: newUpdated.likes } : album
          )
        );

     
    };

    const followUserAlbum = async (album)=>{
        try{

            const addFollows = {
               "currentid": user._id,
               "userid" : album.userdata.id
            }

            const resUserFollows = await axios.put(CONSTANTS.API_URL +"users/add/follow", addFollows,{
                    headers: {
                        token: "Bearer "+ user.accessToken
                    }
                });
            
            console.log(resUserFollows);
            console.log(resUserFollows.data);
            
        }catch(err){
            console.log(err)
        }
    }
  return (
    <div className="widget-home">
        {
            randomAlbums && (
                <>
                    {
                        randomAlbums.map((album, index) => {
                            return <div className="shadebox" key={index}>
                                    <div className="shad-section-top flexme">
                                        <div className="img-home">
                                            {
                                                album.userdata.profilePic === "" ?
                                                <div className="icon-favicon">
                                                   <FaUser /> 
                                                </div>
                                                :
                                                <div className="profile-pic-box">
                                                <div className="profile-item" style={{
                                                        backgroundImage: `url(${album.userdata.profilePic})` 
                                                    }}></div>
                                                </div>
                                            }
                                       
                                        </div>
                                        <div className="content-box-home pd10">
                                          <div className="names-base">
                                            <Link to={"/consumer/"+ album.userdata.id}>
                                            <span>{album.userdata.name}</span>
                                            <span>{album.userdata.surname}</span>
                                            </Link>
                                          </div>
                                          <div className="social-base">
                                            {
                                                album.userdata.instagram && (
                                                    <>
                                                        <Link to={"https://instagram.com/" + album.userdata.instagram } target="_blank">
                                                        <span className="soc-space1">
                                                            <FaInstagram />
                                                        </span>
                                                        <span className="soc-space2">
                                                            {album.userdata.instagram}
                                                        </span>
                                                        </Link>
                                                    </>
                                                )
                                            }

                                            {
                                                album.userdata.facebook && (
                                                    <>
                                                        <Link to={"https://facebook.com/" + album.userdata.facebook }  target="_blank">
                                                            <span className="soc-space1">
                                                                <FaFacebook />
                                                            </span>
                                                            <span className="soc-space2">
                                                                {album.userdata.facebook}
                                                            </span>
                                                        </Link>
                                                    </>
                                                )
                                            }
                                            {
                                                album.userdata.id != user._id && (
                                                    <>
                                                    <button className="soc-space2 no-btn-style" onClick={() => followUserAlbum(album)}>Follow</button>
                                                    </>
                                                )
                                            }
                                             
                                          </div>
                                          <div className="likables">
                                              <span className="soc-space2">
                                                {
                                                    album.title
                                                }
                                              </span>
                                             {
                                                album.likes.includes(user._id) ?
                                                <button className="no-btn-style" onClick={() => unLikeCurrentAlbum(album)}><FaHeart /></button>
                                                :
                                                <button className="no-btn-style" onClick={() => likeCurrentAlbum(album)}><FaRegHeart /></button>
                                             }
                                             
                                          </div>
                                        </div>
                                     
                                    </div>
                                    <div className="shad-section-bottom row">
                                    
                                        {
                                            album.pictureUrls  && (
                                                <>
                                                    {
                                                        album.pictureUrls.map((picUrl, index) => {
                                                            return <div className="col-md-4 rben" key={index}>
                                                                <div className="box-rubs"  style={{
                                                                        backgroundImage: `url(${picUrl})` 
                                                                    }}>

                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </>
                                            ) 
                                        }
                                    </div>
                            </div>
                        })
                    }
                </>
            )
        }
    </div>
  )
}

export default HomeShortList