import React from 'react'
import TopNavigation from '../Components/TopNavigation'
import FooterHolder from '../Components/FooterHolder'

function About() {
  return (
    <div className="stream-container">
      <div className="upper-top">
          <TopNavigation />
      </div>
      <div className="content-display">
        <p>Welcome to PHOTOCUTT &ndash; the ultimate platform for organizing, sharing, and delivering your cherished memories and professional portfolios with ease. Designed with photographers, makeup artists, and event organizers in mind, PHOTOCUTT streamlines the process of managing and distributing visual content, ensuring that every image tells a story and reaches its intended audience effortlessly.</p>
<p>At PHOTOCUTT, we understand the importance of preserving your precious moments and showcasing your creative endeavors. Whether you're a professional photographer looking to deliver stunning images to your clients or a makeup artist wanting to exhibit your latest masterpieces, our platform provides the tools you need to curate captivating albums and share them with the world.</p>
<p>With PHOTOCUTT, creating albums is a breeze. Our user-friendly interface allows you to organize your photos into beautifully curated collections, each capable of holding up to a hundred pictures. Simply upload your images, arrange them to your liking, and voil&agrave; &ndash; your album is ready to be shared.</p>
<p>But the magic doesn't stop there. PHOTOCUTT offers seamless sharing options, allowing you to distribute your albums with ease. Whether you're sending them to clients, showcasing your work on social media, or presenting them to judges at a pageant, our platform ensures that your photos are always just a click away.</p>
<p>What sets PHOTOCUTT apart is our innovative security features. We understand the importance of protecting your images while still allowing easy access for your intended audience. That's why we offer customizable sharing options, including the ability to add a secret code to your albums. With this feature, you can rest assured that only those with the code can download your photos, giving you complete control over who has access to your work.</p>
<p>Whether you're a seasoned professional or just starting out, PHOTOCUTT is here to elevate your visual storytelling experience. Join our community today and discover a new way to showcase your creativity, share your passion, and connect with your audience like never before. Welcome to PHOTOCUTT &ndash; where every picture tells a story, and every story deserves to be shared.</p>
        </div>
        <div className="below-bottom">
            <FooterHolder />
        </div>
    </div>
  )
}

export default About