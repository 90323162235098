import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthLogin from "./Pages/AuthLogin";
import AuthRegistration from "./Pages/AuthRegistration";
import Home from "./Pages/Home";
import AlbumCreate from "./Pages/AlbumCreate";
import MyAlbums from "./Pages/MyAlbums";
import AlbumSingle from "./Pages/AlbumSingle";
import Settings from "./Pages/Settings";
import Consumer from "./Pages/Consumer";
import About from "./Pages/About";
import TermsOfUse from "./Pages/TermsOfUse";
import Privacy from "./Pages/Privacy";
import InfinteGrid from "./Pages/InfinteGrid";

function App() {
  
  return (
    <>
       <Router>
         <div className="main-container">
          <Routes>
             <Route path="/login" element={<AuthLogin />}/>  
             <Route path="/register" element={<AuthRegistration />}/>  
             <Route path="/" element={<Home />}/>  
             <Route path="/create-album" element={<AlbumCreate />}/>
             <Route path="/my-albums" element={<MyAlbums />}/>  
             <Route path="/single-album/:id" element={<AlbumSingle />}/>  
             <Route path="/consumer/:id" element={<Consumer />}/> 
             <Route path="/settings" element={<Settings />}/>  
             <Route path="/about" element={<About />}/> 
             <Route path="/grid-scroll" element={<InfinteGrid />}/> 
             <Route path="/terms-of-use" element={<TermsOfUse />}/> 
             <Route path="/privacy" element={<Privacy />}/> 
          </Routes>
        </div>
       </Router>
       <ToastContainer />
    </>
  );
}

export default App;
