import React from 'react';
import TopNavigation from '../Components/TopNavigation'
import FooterHolder from '../Components/FooterHolder'
import { useSelector } from 'react-redux';
import HomeNoAuth from '../Components/HomeNoAuth';
import HomeShortList from '../Components/HomeShortList';
import FollowList from '../Components/sidewidgets/FollowList';

function Home() {

  const {user}                                       = useSelector((state) => state.auth);
  return (
    <div className="stream-container">
      <div className="upper-top">
          <TopNavigation />
      </div>
      <div className="content-intial">
          {
            user ?
            <div className="flexme">
              <div className="content-display widget-side-home">
                <div className="inner--side-follow">
                Following
                </div>
                {
                  user &&(
                    <FollowList user={user} />
                  )
                }
              </div>

              <div className="content-display main-home">
                    <HomeShortList user={user} />
              </div>
            </div>
            :
            <div className="content-display">
               <HomeNoAuth />
             </div>
          }
      </div>
      <div className="below-bottom">
        <FooterHolder />
      </div>
    </div>
  )
}

export default Home
