import React, { useEffect, useState } from 'react';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function FollowList({user}) {

    const [followersUsers, setFollowersUsers]                     = useState([]);

    useEffect(() => {
       collectUserData();
    },[])

    const collectUserData = async () => {
       try{

        const resData = await axios.get(CONSTANTS.API_URL +"users/followers/list/"+ user._id);

       // console.log(resData);
        console.log(resData.data);
        if(resData.status == 200){
            setFollowersUsers(resData.data);
        }
       }catch(err){
        console.log(err);
       }
    }

  return (
    <div className="side-data">
        
        {
            followersUsers &&(
                <ul>
                    {
                        followersUsers.map((followers, index) =>{
                            return <li className="list-side-item" key={index}>
                                       <div className="img-follow">
                                            {
                                                followers.profilePic === "" ?
                                                 <Link to={"/consumer/"+ followers._id}>
                                                    <div className="icon-favicon">
                                                     <FaUser /> 
                                                    </div>
                                                 </Link>
                                                :
                                                <div className="profile-pic-box">
                                                    <Link to={"/consumer/"+ followers._id}>
                                                    <div className="profile-item" style={{
                                                            backgroundImage: `url(${followers.profilePic})` 
                                                        }}></div>
                                                    </Link>                                                
                                                </div>
                                            }
                                       
                                        </div>
                                    <div className="foll-side">
                                        <div className="a-title">
                                           <Link to={"/consumer/"+ followers._id}>
                                             <span> {followers.name}</span>
                                             <span> {followers.surname}</span>
                                           </Link>
                                        </div>
                                        <div className="b-title">
                                        <span> {followers.email}</span>
                                        </div>
                                    </div>
                                </li>
                        })
                    }
                </ul>
            )
        }
    </div>
  )
}

export default FollowList