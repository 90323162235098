import React, { useEffect, useRef } from 'react'
import './authStyle.css';
import { useSelector, useDispatch } from 'react-redux';
import { register, reset} from '../reduxAuth/authSlice';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Spinner from '../Components/Spinner';
import navLog from './../assets/logo-x1.png';

import backImageBaby from './../assets/photocut-baby.webp';
import backImageCamera from './../assets/photocut-camera.webp';
import backImageLandscape from './../assets/photocut-landscape.jpeg';
import backImageWedding from './../assets/photocut-wedding-pilla.jpg';

function AuthRegistration() {

  const dispatch                                       = useDispatch();
  const {user, isLoading, isError, isSuccess, message} = useSelector((state) => state.auth);

  const emailRef                                       = useRef();
  const passwordRef                                    = useRef();
  const nameRef                                        = useRef();
  const surnameRef                                     = useRef();
  const professionRef                                  = useRef();
  const phonenumberRef                                 = useRef();


  useEffect(() => {
    if(isError){
      toast.error(message)
    }

    if(isSuccess){
      console.log(message);
      toast.success(message)
      resetInputsNow();
    }

    dispatch(reset())
  },[user, isError, isSuccess, message, dispatch])

  
  const handRegister = async (e) => {
    e.preventDefault();

      let emailCheckTest = isValidEmail(emailRef.current.value);
      if(emailCheckTest){

          const userData = {
              "email": emailRef.current.value,
              "password": passwordRef.current.value,
              "name": nameRef.current.value,
              "surname": surnameRef.current.value,
              "profession": professionRef.current.value,
              "phonenumber": phonenumberRef.current.value,
            }        
            
            dispatch(register(userData));
      
      }else {
          toast.error("Email not valid");
      }
  }

  function isValidEmail(email){
      return /\S+@\S+\.\S+/.test(email);
  }

  const resetInputsNow = () => {
    emailRef.current.value                   = "";
    surnameRef.current.value                 = "";
    nameRef.current.value                    = "";
    professionRef.current.value              = "";  
    phonenumberRef.current.value             = "";
    passwordRef.current.value                   = "";
  }

  if (isLoading) {
    return  <Spinner />
  }

  return (
    <>
    <div id="CrossFade">
      <img src={backImageBaby} alt="PHOTOCUT BABY"/>
      <img src={backImageCamera} alt="PHOTOCUT CAMERA"/>
      <img src={backImageLandscape} alt="PHOTOCUT LANDSCAPE"/>
      <img src={backImageWedding} alt="PHOTOCUT WEDDING"/>

        <div className="form-container sign-up-container ">
             <div className="base-logo regi" style={{ 
                        backgroundImage: `url(${navLog})` 
                      }}>

                  </div>
           <form onSubmit={handRegister} className="auther">
            
            <h2>Sign Up</h2>            
              
              <input className="mgInput req" type="email" placeholder="Email*"  ref={emailRef}  required/>
              <input className="mgInput req" type="password" placeholder="Password*" ref={passwordRef} required/>
              <input className="mgInput req" type="text" placeholder="Name*"  ref={nameRef}  required/>
              <input className="mgInput req" type="text" placeholder="Surname*" ref={surnameRef} required/>
              <input className="mgInput req" type="text" placeholder="Phone Number*" ref={phonenumberRef} required/>
              <input className="mgInput" type="text" placeholder="Job Title" ref={professionRef}  />

              <button id="sign-in">Sign Up</button>
          </form>
          <div className="extra-links-auth">
            <div className="flexme inner-distant">
              <Link to="/">Home</Link>
              <Link to="/login">Sign In</Link>
            </div>
          </div>
        </div>
    </div>
  </>
  )
}

export default AuthRegistration