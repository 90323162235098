import React from 'react'
import FooterHolder from '../Components/FooterHolder'
import TopNavigation from '../Components/TopNavigation'

function Privacy() {
  return (
    <div className="stream-container">
    <div className="upper-top">
        <TopNavigation />
    </div>
    <div className="content-display">
    <p>**PHOTOCUTT Privacy Policy**</p>
<p>Protecting your privacy is important to us. This Privacy Policy outlines how PHOTOCUTT collects, uses, and safeguards your personal information when you use our website and services. By accessing or using PHOTOCUTT, you consent to the terms of this Privacy Policy.</p>
<p>**1. Information We Collect:**</p>
<p>- **Registration Data:** When you create an account on PHOTOCUTT, we collect information such as your name, email address, and password. This information is used to authenticate your identity and protect access to your data.</p>
<p>- **Usage Data:** We may collect information about how you interact with our website, including your IP address, browser type, pages visited, and other usage statistics. This data helps us improve our services and provide a better user experience.</p>
<p>**2. Use of Information:**</p>
<p>- **Authentication:** We use your registration data to verify your identity and secure access to your account and data.</p>
<p>- **Communication:** We may use your email address to send you important updates, notifications, or promotional offers related to PHOTOCUTT. You can opt out of promotional emails at any time.</p>
<p>**3. Data Security:**</p>
<p>- **Protection Measures:** We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.</p>
<p>- **Data Retention:** We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law.</p>
<p>**4. Third-Party Disclosure:**</p>
<p>- **No Selling of Data:** We do not sell, trade, or otherwise transfer your personal information to third parties for marketing or commercial purposes.</p>
<p>**5. Your Choices:**</p>
<p>- **Account Settings:** You can update or modify your account information and communication preferences at any time by accessing your account settings on PHOTOCUTT.</p>
<p>**6. Children's Privacy:**</p>
<p>- **Age Requirement:** PHOTOCUTT is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13 years of age. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us immediately.</p>
<p>**7. Changes to Privacy Policy:**</p>
<p>- **Updates:** We may update this Privacy Policy from time to time to reflect changes in our practices or to comply with legal requirements. We will notify you of any material changes by posting the updated policy on our website or by sending you a notification.</p>
<p>**8. Contact Us:**</p>
<p>- **Questions:** If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at tsdevcut@gmail.com.</p>
<p>By using PHOTOCUTT, you acknowledge that you have read, understood, and agree to abide by this Privacy Policy. Thank you for trusting us with your personal information.</p>
      </div>
      <div className="below-bottom">
          <FooterHolder />
      </div>
  </div>
  )
}

export default Privacy