import React, { useEffect, useRef } from 'react';
import './authStyle.css';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../Components/Spinner';
import { login, reset } from '../reduxAuth/authSlice';
import navLog from './../assets/logo-x1.png';

import backImageBaby from './../assets/photocut-baby.webp';
import backImageCamera from './../assets/photocut-camera.webp';
import backImageLandscape from './../assets/photocut-landscape.jpeg';
import backImageWedding from './../assets/photocut-wedding-pilla.jpg';

function AuthLogin() {

  const navigate                                           = useNavigate();
  const dispatch                                           = useDispatch();
  const {user, isLoading, isError, isSuccess, message}     = useSelector((state) => state.auth);


  useEffect(() => {

        if(isError){
            toast.error(message)
        }
      
        if(isSuccess || user){
            navigate('/');
        }

       // dispatch(reset())
    },[user, isError, isSuccess, message, navigate, dispatch])

    const emailRef                                           = useRef();
    const passwordRef                                        = useRef();

    const handleLogin = async (e) => {
      e.preventDefault();

        let emailCheckTest = isValidEmail(emailRef.current.value);
        if(emailCheckTest){

            const userData = {
                "email": emailRef.current.value,
                "password": passwordRef.current.value
              }        
              dispatch(login(userData));
        
        }else {
            toast.error("Email not valid");
        }
    }

    function isValidEmail(email){
        return /\S+@\S+\.\S+/.test(email);
    }

    if (isLoading) {
      return  <Spinner />
    }
  return (
    <>
      <div id="CrossFade">
        <img src={backImageBaby} alt="PHOTOCUT BABY"/>
        <img src={backImageCamera} alt="PHOTOCUT CAMERA"/>
        <img src={backImageLandscape} alt="PHOTOCUT LANDSCAPE"/>
        <img src={backImageWedding} alt="PHOTOCUT WEDDING"/>

          <div className="form-container sign-in-container">
                 <div className="base-logo" style={{ 
                        backgroundImage: `url(${navLog})` 
                      }}>

                  </div>
             <form onSubmit={handleLogin} className="auther">
              
              <h2>Sign in</h2>            
                
                <input id="emaillog" className="mgInput" type="email" placeholder="Email"  ref={emailRef}  required=""/>
                <input id="passwordlog" className="mgInput" type="password" placeholder="Password" ref={passwordRef} required="" />

                <button id="sign-in">Sign In</button>
            </form>
            <div className="extra-links-auth">
              <div className="flexme inner-distant">
                <Link to="/">Home</Link>
                <Link to="/register">Sign Up</Link>
              </div>
            </div>
          </div>
      </div>
    </>
  )
}

export default AuthLogin