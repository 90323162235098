import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import axios from "axios";
import * as CONSTANTS from "./../CONSTANTS";
import { FaTrash } from 'react-icons/fa';
import InfiniteScroll from 'react-infinite-scroll-component'
import Masonry from 'react-masonry-css'
import { Flex } from '@chakra-ui/react'

function InfinteGrid() {
    
    const {user}                                                     = useSelector((state) => state.auth);

    const [pictureList, setPictureList]                              = useState([]);
    const [pageNum, setPageNum]                                      = useState(1);
    const [listCompleted, setListCompleted]                          = useState(false);
    const pageSize                                                   = 10;
    const [hasMore, setHasMore]                                      = useState(true);

    useEffect(() => {
      if(listCompleted == false ){
        if(hasMore){
          collectMyPictures();
        }
        window.addEventListener('scroll', handleScroll);
      }
    },[hasMore]);

  
    useEffect(() => {
      if(listCompleted === true){
  
        window.removeEventListener('scroll', handleScroll);
        setListCompleted(false)
      }
    },[listCompleted])

    const handleScroll = () => {
    
      if(listCompleted == false ){
        const ihscrollTop = window.innerHeight + Math.round(document.documentElement.scrollTop + 10);
    
          const documentHeight = Math.round(document.documentElement.offsetHeight);
         
          if(ihscrollTop >= documentHeight && listCompleted == false) {
           
            setHasMore(true);
          }
      }
    };
    const reviewHassMore = () => {
      console.log("reviewHassMore")
        console.log(hasMore);
        console.log("Page Number")
        console.log(pageNum);
        console.log("######");
    }

    const collectMyPictures = async () => {
        
        try{
  
          const picCollect = {
            "pageNum" :pageNum,
            "pageSize" : pageSize
          }
         // console.log(picCollect);
          //const albumCode = "b6lhC8GLZt";
          const albumCode = "tR9tBcIFaX";
            const responseCurrent = await axios.put(CONSTANTS.API_URL+"pictures/collect/list/" + albumCode, picCollect);
            

            if(responseCurrent.data.length == 0){
           
              setListCompleted(prevopt => true);
            } else {
              setPictureList(prevList => [...prevList, ...responseCurrent.data]);
              setPageNum(prevPage => prevPage + 1);
            }
            
            /*
            //  console.log("Items Count : " + responseCurrent.data.length);
            if (responseCurrent.data.length === 0) {
                setHasMore(false);
                console.log("Has more is being turned false");

                if(responseCurrent.data.length === 0){
                    console.log("PEACE...");
                }
            } else {
                setHasMore(true);
                setPictureList(prevList => [...prevList, ...responseCurrent.data]);
                setPageNum(prevPage => prevPage + 1); 
            }
            */
                     
            setHasMore(false);
            //setProcessing(false);

        }catch(err){
          console.log(err);
         // setProcessing(false);
        }
      }
  
    const deleteThisPicture = (picture) => {
      console.log(picture);
    }

    const Loader = () => (
        <div className="img-source">
           <img src={CONSTANTS.BLUE_PROCESSING} className="proc" />
        </div>
    )

  const breakpointColumnsObj = {
		default: 4,
		1400: 3,
    900: 2,
		700: 1,
	}

	const columnClassName = 'my-masonry-grid_column'

	const gutterSpace = '30px'

	const masonryStyles = {
		ml: `-${gutterSpace}`,
	}

	const selector = `& .${columnClassName}`

	masonryStyles[selector] = {
		pl: gutterSpace,
		backgroundClip: 'padding-box',
	}
  

  return (
    <div className="stream-container">
        {
           (pictureList.length > 0) && (
                <>
                    <InfiniteScroll
                        next={reviewHassMore()}
                        dataLength={pictureList.length}
                        hasMore={hasMore}
                        loader={<Loader />}
                    >
                        <Flex 
                            className={columnClassName} 
                            as={Masonry}
                            breakpointCols={breakpointColumnsObj}
                            sx={masonryStyles}
                            mt='2rem'
                            >
                            {
                                pictureList.map((album, key) => {
                                        return <div className="pic-box" key={key} 
                                                  >
                                               
                                                    <div className="position-delete">
                                                        <button className="btn btn-over btn-trasher" onClick={() => deleteThisPicture(album)}><FaTrash /></button>
                                                    </div>
                                                 
                                                    <img 
                                                      className="img-special"
                                                       src={album.picurl}
                                                       loading="lazy" 
                                                       alt="PHOTO CUTTS"
                                                      />
                                                     
                                                </div>
                                            })
                            }
                        </Flex>
                    </InfiniteScroll>
                </>
            )
        }
    </div>
  )
}

export default InfinteGrid