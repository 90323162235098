import React, { useEffect, useState } from 'react'
import TopNavigation from '../Components/TopNavigation'
import { useSelector } from 'react-redux';
import axios from "axios";
import { toast } from 'react-toastify';
import * as CONSTANTS from "./../CONSTANTS";
import { Link } from 'react-router-dom';
import FooterHolder from '../Components/FooterHolder';

function MyAlbums() {
  const {user}                                          = useSelector((state) => state.auth);

  const [myAlbums, setMyAlbums]                         = useState([]);

  useEffect(() => {
    collectMyAlbums();
  },[])

  const collectMyAlbums = async () => {
    try{

      const res = await axios.get(CONSTANTS.API_URL +"albums/current/benefactor/" + user._id, {
          headers: {
              token: "Bearer "+ user.accessToken
          }
      });

      console.log(res.data);
      setMyAlbums(res.data);
    }catch(err){
      console.log(err);
    }
  }
  return (
    <div className="stream-container">
        <div className="upper-top">
            <TopNavigation />
        </div>
        <div className="content-display">
           <div className="row">
              {

                myAlbums.length > 0 ?
                <>
                  {
                      myAlbums.map((alby, index) => {
                        return (<div className="col-md-3" key={index}>
                                    <div className={"album-block tpnum"+ (index & 1) }>
                                      <h4><Link to={"/single-album/" + alby._id}>{alby.title}</Link></h4>
                                    </div>
                                </div>)
                      })
                    }
                </>
                : 
                 <>
                   <div className="a1Size">No Albums Create</div>
                   <div className="a2Size">Please creae an album of yourself.</div>
                 </>
              }
           </div>
        </div>
        <div className="below-bottom">
          <FooterHolder />
        </div>
    </div>
  )
}

export default MyAlbums