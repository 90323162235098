import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import TopNavigation from '../Components/TopNavigation';
import ProfileWidget from './../Components/settings/ProfileWidget.js'
import HelpCenterWidget from './../Components/settings/HelpCenterWidget.js'
import ReportWidget from './../Components/settings/ReportWidget.js'

function Settings() {
    const {user}                                          = useSelector((state) => state.auth);

    const [activeTab, setActiveTab]                       = useState('profile'); // Default active tab
  
    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };
  
    // Function to render the active widget based on the activeTab state
    const renderActiveWidget = () => {
      switch (activeTab) {
        case 'profile':
          return <ProfileWidget user={user} />;
        case 'helpCenter':
          return <HelpCenterWidget user={user} />;
        case 'report':
          return <ReportWidget user={user} />;
        default:
          return null;
      }
    };

  return (
    <div className="stream-container">
        <div className="upper-top">
            <TopNavigation />
        </div>
        
            <div className="flexme">
                <div className="content-display widget-side-home">
                   <div className="initial-title">Settings</div>

                   <ul className="side-text-listin settings-listing mgtop10">
                    <li className="tab" onClick={() => handleTabClick('profile')}>Profile</li>
                    <li className="tab" onClick={() => handleTabClick('helpCenter')}>Help Center</li>
                    <li className="tab" onClick={() => handleTabClick('report')}>Report</li>
                   </ul>
                </div>
                <div className="content-display main-home">
                 
                      {renderActiveWidget()}
                </div>
           
            </div>
    </div>
  )
}

export default Settings